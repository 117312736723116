module.exports = {
id:127,
title: 'Defending Monroe’s Rights as a Community School',
date: '2025-01-04',
content: `<p>Hope you've had a nice vacation. The short version is this:</p>
<p>Carvalho is scheduled to come to Monroe this Tuesday, January 7. Because he is violating our rights as a community school, we are asking teachers to put<a href="https://drive.google.com/file/d/1-1EdGg1RZBNRPJhrSkDkvliSDg_mgx0z/view"> our protest poster</a> on their outward facing doors. If you are interested in participating, please reply to this email and I will get you a poster.&nbsp;</p>
<h2>Full Version</h2>
<p>As you are aware, the Carvalho administration is violating Monroe&rsquo;s rights as a community school by mandating 50% of our Professional Development sessions. In June, Monroe's LSLC unanimously approved&ndash;led by Monroe Chapter Chair Lourdes Quevedo and Principal Mat Diamond&ndash;a slate of Professional Development sessions that best serves our school.</p>
<p>The district, however, has contravened this vote by imposing its own PD agenda. In other words, LAUSD is preventing Monroe LSLC from following through on what we approved.</p>
<p>We have protested this and have filed a grievance against the district over it, but the district is attempting to wait us out, so we are escalating our protest.</p>
<p>LAUSD Superintendent Alberto Carvalho is scheduled to come to Monroe this Tuesday, January 7. Beginning this week when we return to school, we'd like teachers to put <a href="https://drive.google.com/file/d/1-1EdGg1RZBNRPJhrSkDkvliSDg_mgx0z/view">our protest poster</a> on their doors facing outwards. Slogans on the posters include:</p>
<ul>
<li>LAUSD: Hands off our PD!</li>
<li>Community schools choose their PD, not the district!</li>
<li>One size fits all district PD don&rsquo;t work!</li>
</ul>
<p>The posters also emphasize that community schools&rsquo; PD are:</p>
<ul>
<li>designed by teachers made for teachers</li>
<li>treat our students as more than just numbers</li>
<li>allow for effective educator collaboration.</li>
</ul>
<p>Our poster <a href="https://drive.google.com/file/d/1-1EdGg1RZBNRPJhrSkDkvliSDg_mgx0z/view">can be seen here</a>.</p>
<p>If you are interested in participating, please reply to this email and I will get you a poster.</p>
<h2>How LAUSD Infringing on Community Schools&rsquo; Rights</h2>
<p>Leaving aside what is state-mandated, conventional LAUSD schools have 50% power over selecting Professional Development. However, the 2022-2025 <a href="https://utla.net/app/uploads/2023/12/FINAL-CBA-2022-2025-UTLA-Contract-1.pdf">Collective Bargaining Agreement</a> states &ldquo;the LSLC at each Community School shall have decision making purview over&hellip;All site-based professional development.&rdquo; Clearly, Community Schools&rsquo; Local School Leadership Councils have 100% control over PDs.</p>

<p>Community Schools are granted this kind of autonomy because they face distinct challenges that are best supported at the school site rather than via one-size-fits all, district-imposed PDs.</p>

<p>LAUSD is now attempting to strip all Community Schools of this contractual autonomy. The May 20, 2024 LAUSD Memorandum <em>School-Site Professional Development Priorities and Banked Time Tuesdays for Schools 2024-25 </em>states &ldquo;All schools will deliver the &lsquo;Data-Driven Decision Making - Aligning 2024 Data to the Instructional Priorities&rsquo; module on August 20, 2024.&rdquo; UTLA is aware of this and is currently considering how to respond to this violation of our contract.</p>

<p>This limitation on Community School control over PDs is being done regardless of whether the school is a &ldquo;priority school.&rdquo; Priority schools are some of the LAUSD&rsquo;s &ldquo;low-performing&rdquo; schools. Unlike Community Schools, priority schools are not part of the LAUSD-UTLA contract&ndash;they are Carvalho&rsquo;s creation.</p>

<p>In June, Monroe's LSLC agreed on a slate of professional development sessions, but the district has contravened this vote by imposing its own PD agenda. In other words, LAUSD is preventing Monroe LSLC from following through on what we approved.</p>
<p>Since the June vote was unanimous, it appears Principal Mat Diamond and Monroe UTLA are on the same side on this one, though obviously district actions put Diamond in a difficult position.</p>
<p><strong><br /></strong><strong>Carvalho&rsquo;s &lsquo;Priority Schools&rsquo;</strong><strong></strong></p>
<p>As of July 1, Monroe is a priority school. In <a href="https://edsource.org/2024/lausds-100-priority-schools-target-districts-highest-need-students/706760">LAUSD&rsquo;s 100 priority schools target district&rsquo;s highest-need students</a>, Mallika Seshardi of <em>EdSource </em>explains:</p>

<blockquote>
&ldquo;Shortly after Alberto Carvalho became superintendent of LAUSD two years ago, he created a 100-day plan and named the district&rsquo;s top 100 priority schools&hellip;[they] are some of the district&rsquo;s lowest-performing campuses in all measures, and&hellip;would serve as the focal point of various district initiatives, such as decisions on adding additional instructional days to help students recover from pandemic learning loss and the new policy precluding charter schools from sharing their campuses.&rdquo;</blockquote>

<p>Considerations for priority school placement include:</p>
<ul>
<li>attendance rates</li>
<li>SBAC scores</li>
<li>A-G course completion</li>
<li>proportion of students who are English learners</li>
</ul>
<p>Priority schools comprise roughly 10% of LAUSD&rsquo;s overall enrollment. Below is a comparison of priority schools&rsquo; performance with LAUSD's overall performance:</p>
<ul>
<li>For 2022-2023, schools on the priority list had a chronic absenteeism rate of 38%, compared to 33% for LAUSD as a whole.</li>
<li>At priority schools, 23% met or exceeded English standards and 16% met or exceeded math standards. For LAUSD as a whole, those numbers were 41% and 31% respectively.</li>
<li>High School graduation rates were 81% at priority schools and 90% for the district as a whole.&nbsp;</li>
</ul>
<h2>Why Is Monroe Given a &lsquo;Priority Schools&rsquo; Designation, Limiting Our Autonomy?</h2><p>The high schools on the 2023-2024 LAUSD priority list include, among others, Dorsey, Fremont, Jefferson, Manual Arts, and Panorama High Schools. What do these and the others on the priority list all have in common? Students who overwhelmingly come from low socioeconomic backgrounds.</p>

<p>Obviously our students' low test scores, and graduation and attendance rates are not because of the imagined failings of Monroe teachers or administrators, but instead because of our students&rsquo; challenging backgrounds.</p>

<p>UTLA&rsquo;s solution is to encourage community schools, so we can attempt to deal with the many problems our students face, as opposed to just focusing on raw indicators that don&rsquo;t properly adjust for the difficulties these schools face.</p>
<p>You may recall last year we did an extensive data collection to determine what Monroe&rsquo;s main priorities should be for its students and families, and how our staff can best serve them. As we move into our second year as a community school, our focus is to put these priorities into action, but we cannot do this if LAUSD is limiting our autonomy.</p>`
}